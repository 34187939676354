import Notiflix from 'notiflix'

// Initialize Notify with custom options
export default class Notifier {
	init() {
		this.initNotiflix()
		window.Notiflix = Notiflix
	}

	initNotiflix() {
		Notiflix.Notify.init({
			ID: 'ffu-notice',
			className: 'ffu-notiflix',
			fontFamily: 'Poppins',
			timeout: 5000,
			messageMaxLength: 400,
			success: {
				background: '#82d616'
			},
			failure: {
				background: '#ea0606'
			},
			warning: {
				background: '#fbcf33',
				textColor: '#414042',
				notiflixIconColor: '#414042'
			},
			info: {
				background: '#0ca5c2',
				notiflixIconColor: '#fff'
			},
			cssAnimationStyle: 'from-right'
		})

		Notiflix.Report.init({
			width: '300px',
			messageMaxLength: 4000,
			plainText: false
		})

		Notiflix.Confirm.init({
			titleColor: '#414042',
			okButtonColor: '#fff',
			okButtonBackground: '#ea0606',
			cancelButtonColor: '#fff'
		})
	}
}
