export default class Login {
	constructor() {
		this.container = document.querySelector('.login')
		this.form = this.container.querySelector('.login-form')
		this.formElements = {
			email: this.form.elements.email,
			password: this.form.elements.password
		}
		this.submitButton = this.form.querySelector('.login-formSubmit')
		this.inputs = [...this.form.querySelectorAll('.login-formFieldInput')]
		this.warningMessage = this.form.querySelector('.login-formWarning')
		this.googleRecaptchaClientKey = this.form.getAttribute('data-publickey')
		this.route = this.form.getAttribute('data-route')
		this.showPasswordButton = this.form.querySelector('.login-formFieldButton')
		this.tooltip = this.form.querySelector('.login-tooltip')
		this.head = document.querySelector('head')

		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.onShowPassword = this.onShowPassword.bind(this)
	}

	init() {
		this.insertRecaptchaScriptApi()
		this.removeAttributes()
		this.addEvents()
	}

	removeAttributes() {
		this.form.removeAttribute('data-route')
		this.form.removeAttribute('data-publickey')
	}

	addEvents() {
		this.inputs.forEach((input) => {
			input.addEventListener('blur', this.handleBur)
		})

		this.form.addEventListener('submit', this.onFormSubmit)
		this.showPasswordButton.addEventListener('click', this.onShowPassword)
	}

	handleBur(e) {
		const target = e.target
		target.classList[target.value.length ? 'add' : 'remove']('filled')
	}

	async onFormSubmit(e) {
		e.preventDefault()
		this.token = await this.grecaptcha.execute(this.googleRecaptchaClientKey, {
			action: 'submit'
		})
		this.getFormValues()
	}

	onShowPassword(e) {
		e.preventDefault()
		const target = e.target
		const input = this.formElements.password
		const type = input.getAttribute('type')

		target.classList[type === 'text' ? 'remove' : 'add']('show')
		input.setAttribute('type', type === 'password' ? 'text' : 'password')
	}

	/**
	 * Insert Google captcha script API
	 */
	insertRecaptchaScriptApi() {
		window.initCaptcha = () => {
			this.initCaptcha()
		}
		const script = document.createElement('script')
		script.setAttribute(
			this.consent?.recaptcha ? 'type' : 'data-type',
			'application/javascript'
		)
		script.setAttribute(this.consent?.recaptcha ? 'name' : 'data-name', 'recaptcha')
		script.setAttribute(
			'src',
			`https://www.google.com/recaptcha/api.js?render=${this.googleRecaptchaClientKey}`
		)
		script.setAttribute('onload', 'initCaptcha()')
		this.head.appendChild(script)
	}

	initCaptcha() {
		grecaptcha.ready(() => {
			this.grecaptcha = grecaptcha
		})
	}

	getFormValues() {
		this.removeErrors()
		this.warningMessage.textContent = ''
		const errors = this.validateForm()

		if (errors.length > 0) {
			this.displayFormErrors(errors)
		} else {
			let data = {}
			data.captcha = this.token

			Object.values(this.formElements).forEach((element) => {
				data[element.getAttribute('id')] = element.value
			})

			data = JSON.stringify({ data })
			this.sendData(data)
		}
	}

	removeErrors() {
		this.warningMessage.classList.remove('error')
		Object.values(this.formElements).forEach((element) => {
			element.classList.remove('error')
			element.parentNode.nextElementSibling.textContent = ''
		})
	}

	displayFormErrors(errorArray) {
		errorArray.forEach((element) => {
			if (this.formElements[element.type]) {
				this.formElements[element.type].parentNode.classList.add('error')
				this.formElements[element.type].parentNode.nextElementSibling.textContent =
					element.message
			} else {
				this.warningMessage.textContent = element.message
			}
		})
	}

	validateForm() {
		this.warningsArray = []

		Object.values(this.formElements).forEach((element) => {
			const elementName = element.name
			const type = element.type
			const value = element.value

			// don't check the following elements:
			if (!elementName || ['reset', 'submit', 'button'].includes(type)) return false

			if (value === '') {
				this.warningsArray.push({
					type: elementName,
					message: 'This field is required!'
				})
			} else if (
				elementName === 'email' &&
				(!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || value.length > 254)
			) {
				this.warningsArray.push({
					type: elementName,
					message: 'Email is not valid!'
				})
			}
		})

		return this.warningsArray
	}

	async sendData(data) {
		this.submitButton.classList.add('loading')
		setTimeout(async () => {
			try {
				const rawResponse = await fetch(this.route, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					body: data
				})
				const content = await rawResponse.json()
				const response = content[0]

				if (response.warning && response.warning.status === 'success') {
					window.location.href = response.warning.message
				} else {
					throw response
				}
			} catch (err) {
				this.submitButton.classList.remove('loading')
				this.warningMessage.classList.add('error')
				this.displayFormErrors(Object.values(err))

				console.warn(err)
			}
		}, 1000)
	}
}
